export const Bus = () => import('../../components/bus.ts' /* webpackChunkName: "components/bus" */).then(c => wrapFunctional(c.default || c))
export const AirTicketAreaPopup = () => import('../../components/AirTicket/AreaPopup.vue' /* webpackChunkName: "components/air-ticket-area-popup" */).then(c => wrapFunctional(c.default || c))
export const AirTicketAreaSelect = () => import('../../components/AirTicket/AreaSelect.vue' /* webpackChunkName: "components/air-ticket-area-select" */).then(c => wrapFunctional(c.default || c))
export const AirTicketSearch = () => import('../../components/AirTicket/Search.vue' /* webpackChunkName: "components/air-ticket-search" */).then(c => wrapFunctional(c.default || c))
export const AirTicketHelper = () => import('../../components/AirTicket/helper.js' /* webpackChunkName: "components/air-ticket-helper" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../components/Avatar/index.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const AsideFair = () => import('../../components/AsideFair/index.vue' /* webpackChunkName: "components/aside-fair" */).then(c => wrapFunctional(c.default || c))
export const CompleteInfo = () => import('../../components/CompleteInfo/index.ts' /* webpackChunkName: "components/complete-info" */).then(c => wrapFunctional(c.default || c))
export const EditVisitor = () => import('../../components/EditVisitor/index.vue' /* webpackChunkName: "components/edit-visitor" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../components/Empty/index.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const FairUser = () => import('../../components/FairUser/index.vue' /* webpackChunkName: "components/fair-user" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Footer2 = () => import('../../components/Footer2/index.vue' /* webpackChunkName: "components/footer2" */).then(c => wrapFunctional(c.default || c))
export const GoodsFairTag = () => import('../../components/Goods/fairTag.vue' /* webpackChunkName: "components/goods-fair-tag" */).then(c => wrapFunctional(c.default || c))
export const GoodsFairTagCopy = () => import('../../components/Goods/fairTag_copy.vue' /* webpackChunkName: "components/goods-fair-tag-copy" */).then(c => wrapFunctional(c.default || c))
export const GoodsIndexSeo = () => import('../../components/Goods/index-seo.vue' /* webpackChunkName: "components/goods-index-seo" */).then(c => wrapFunctional(c.default || c))
export const Goods = () => import('../../components/Goods/index.vue' /* webpackChunkName: "components/goods" */).then(c => wrapFunctional(c.default || c))
export const GoodsItemSeo = () => import('../../components/Goods/item-seo.vue' /* webpackChunkName: "components/goods-item-seo" */).then(c => wrapFunctional(c.default || c))
export const GoodsItem = () => import('../../components/Goods/item.vue' /* webpackChunkName: "components/goods-item" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/index.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderVisa = () => import('../../components/HeaderVisa/index.vue' /* webpackChunkName: "components/header-visa" */).then(c => wrapFunctional(c.default || c))
export const HotelBooking = () => import('../../components/Hotel/Booking.vue' /* webpackChunkName: "components/hotel-booking" */).then(c => wrapFunctional(c.default || c))
export const HotelItem = () => import('../../components/Hotel/HotelItem.vue' /* webpackChunkName: "components/hotel-item" */).then(c => wrapFunctional(c.default || c))
export const HotelCityData = () => import('../../components/Hotel/city-data.ts' /* webpackChunkName: "components/hotel-city-data" */).then(c => wrapFunctional(c.default || c))
export const HotelShared = () => import('../../components/Hotel/shared.ts' /* webpackChunkName: "components/hotel-shared" */).then(c => wrapFunctional(c.default || c))
export const Join = () => import('../../components/Join/index.vue' /* webpackChunkName: "components/join" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/Login/index.ts' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const LoginHeader = () => import('../../components/LoginHeader/index.vue' /* webpackChunkName: "components/login-header" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/Map/index.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const MediaBox = () => import('../../components/MediaBox/index.vue' /* webpackChunkName: "components/media-box" */).then(c => wrapFunctional(c.default || c))
export const NewsExh = () => import('../../components/NewsExh/index.vue' /* webpackChunkName: "components/news-exh" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination/index.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const ReservationExh = () => import('../../components/ReservationExh/index.vue' /* webpackChunkName: "components/reservation-exh" */).then(c => wrapFunctional(c.default || c))
export const SectionTitle = () => import('../../components/SectionTitle/index.vue' /* webpackChunkName: "components/section-title" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar/index.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SingleUpload = () => import('../../components/SingleUpload/index.vue' /* webpackChunkName: "components/single-upload" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../components/Toast/index.ts' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const SliderImage = () => import('../../components/sliderImage/index.vue' /* webpackChunkName: "components/slider-image" */).then(c => wrapFunctional(c.default || c))
export const SidebarComponentsMenuItem = () => import('../../components/Sidebar/components/MenuItem.vue' /* webpackChunkName: "components/sidebar-components-menu-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
